.mainNav {
    text-align: center;
    background-color: var(--primary-color);
    color: white;
    margin-top: -0.25rem;
    z-index: 4000;
}

.mainNav .mobile-menu {
    display: inline-block;
    position: fixed;
    z-index: 999999;
    bottom: 1.2rem;
    left: 1.2rem;
    font-size: 2.5rem;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
    display: none;
    border: 1px solid white;
}

.mainNav .adm-profile-link span {
    display: none;
    font-size: 1.8rem !important;
}

.mainNav .adm-profile-link {
    display: inline-block;
    /*position: fixed;
    */border: 1px solid red;
    position: fixed;
    color: white;
    bottom: 1.2rem;
    right: 1.2rem;
    height: 4rem;
    font-size: 2.5rem;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    background-color: var(--secondary-color);
    border: 1px solid white;
    z-index: 999999;
}

.mainNav .adm-profile-link:hover {
    width: auto;
    padding: 0 1rem;
}

.mainNav .adm-profile-link:hover span {
    display: inline-block;
}

.mainNav a {
    color: var(--font-color-light)
}

.mainNav ul {
    display: flex;
}

.mainNav ul li {
    list-style: none;
    font-weight: 500;
    cursor: pointer;
}

.mainNav ul li a, .mainNav ul li span {
    display: inline-block;
    height: 100%;
    width: 100%;
    padding: 1rem;
}

.mainNav ul li a:hover {
    background-color: var(--secondary-color);
}

.mainNav ul li span:hover {
    cursor: default;
}

.nav-dropdown-parent {
    position: relative;
}

.nav-dropdown-parent:hover .nav-dropdown {
    opacity:  1;
    top: 4.1rem;
    pointer-events: auto;
}

.nav-dropdown {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 5rem;
    display: block;
    color: var(--font-color-dark);
    transition: all 0.2s ease;
    pointer-events: none;
    background: var(--primary-color);
    color: var(--font-color-light);
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    z-index: 999999;
    overflow: hidden;
}

.nav-dropdown ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    display: block;
    position: relative;
}

.nav-dropdown ul li {
    list-style: none;
    text-align: left;
    white-space: nowrap;
}

.nav-dropdown ul li:hover {
    background-color: var(--secondary-color);
}