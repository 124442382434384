.with-sidebar {
    display: grid;
    grid-template-columns: 1fr 34rem;
    padding: 2rem var(--home-padding);
}

.sidebar  {
    max-width: 100%;
}

.sidebar > div:nth-child(1) {
    max-width: 100%;
}

.ws-content {
    min-height: 40rem;
    margin-right: 3rem;
}

.s-box {
    border-top: 10px solid var(--primary-color);
    box-shadow: var(--shadow-md);
    margin: 0 0 3rem;
}

.s-box-header {
    padding: 0.5rem 1rem 0.5rem;
    background-color: var(--primary-color-light);  
    color: var(--primary-color);
}


.s-box-body {
    padding: 1rem;
}

.s-box-body h5 {
    font-size: medium;
    margin: 0.5rem 0 0.8rem;
}

.s-facilities-list li {
    transition: all 0.3s ease;
    list-style-position:outside;
    white-space: nowrap;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.s-facilities-list li a {
    display: inline-block;
    height: 100%;
    padding: 0.8rem 0;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    text-overflow: ellipsis;
    width: calc(100% - 2rem);
    overflow: hidden;
}

.s-facilities-list li a:hover {
    text-decoration: underline;
    color: var(--primary-color)
}
