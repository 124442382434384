.createprofile-box > div:nth-of-type(1) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1.5rem;
}

.createprofile-box > div:nth-of-type(1) > div:nth-child(1) {
    margin-right: 1rem;
}

.createprofile-box label, .createprofile-box input {
    display: block;
}

.createprofile-box input,
.createprofile-box textarea {
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: block;
    width: 100%;
    padding: 0.4rem 0.8rem;
    border-radius: 0.5rem;
}

.createprofile-box textarea {
    min-height: 20rem;
}

.createprofile-box > div:nth-of-type(2) > div {
    margin-bottom: 1.5rem;
}