.ap-main-section {
    padding: 1rem;
    background-color: #F7F7F7;
    border-radius: 0.5rem;
    min-height: 100%;
}

.ap-box {
    background-color: white;
    padding: 0.5rem 1rem;
    box-shadow: var(--shadow);
    border-radius: 0.4rem;
    margin: 0 0 1rem;
}

.ap-main-section-header {
    margin-bottom: 1rem;
}

.ap-main-section-header h2 {
    font-size: 2.2rem;
    opacity: 0.85;
}

.ap-main-section h3 {
    margin: 0;
    font-size: 1.7rem;
    /* margin-bottom: 1rem; */
}

.file-input-container {
    padding: 0 0 0.5rem;
}

.photo-upload-select {
    border: 1px dashed rgba(0, 0, 0, 0.3);
    padding: 1rem;
    width: 20rem;
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 0.5rem;
    color: rgba(0, 0, 0, 0.75);
}

.document-upload-select,
.video-upload-select {
    border: 1px dashed rgba(0, 0, 0, 0.3);
    padding: 1rem;
    width: 100%;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 0.5rem;
    color: rgba(0, 0, 0, 0.75);
}

.document-upload-btn,
.photo-upload-btn,
.video-upload-btn {
    padding: 0.5em 1rem;
    background: var(--primary-color);
    color: white;
    border-radius: 0.5rem;
    font-size: 1.5rem;
    margin-top: 1rem;
    transition: all 0.2s ease;
}
.document-upload-btn:hover,
.photo-upload-btn:hover,
.video-upload-btn:hover {
    opacity: 0.7;
}
.document-upload-btn:focus,
.photo-upload-btn:focus,
.video-upload-btn:focus {
    outline: none;
}

.selected-file-info {
    margin: 2rem 0 1rem;
}

.selected-file-info h5 {
    font-size: 1.5rem;
}

.selected-file-info ul {
    font-size: 1.4rem;
    /* font-style: italic; */
    border-left: 3px solid var(--secondary-color);
    padding-left: 2rem;
    margin: 0.5rem 0;
}

.selected-file-info ul li {
    display: grid;
    grid-template-columns: 18rem 1fr;
    margin: 0.2rem 0;
}
.selected-file-info ul li span:nth-child(2) {
    font-style: italic;
    font-weight: bold;
}
