@import url(https://fonts.googleapis.com/css2?family=Arimo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo&family=Cabin&display=swap);
@media screen and (max-width: 1296px) {
    /* * FOOTER */
    .footer-container {
        grid-template-columns: 1fr 1fr !important;
    }
    .footer-container > div:nth-child(2) {
        grid-column: 2/3;
        grid-row: 1/3;
    }

    /* * END: FOOTER */

}

@media screen and (max-width: 1125px) {
    .with-sidebar {
        grid-template-columns: 1fr 30rem !important;
    }
    
}
@media screen and (max-width: 1073px) {

    .with-sidebar {
        grid-template-columns: 1fr 29rem !important;
    }

    /* * FOOTER */
    .footer-container {
        grid-template-columns: 2fr 1fr !important;
    }

    .company-links-list {
        grid-template-columns: 1fr !important;
    }
    .company-links-list li {
        margin: 0.2rem 0;
    }
    /* * END FOOTER */

    .proc {

        grid-template-columns: 18rem 1fr !important;
    }

}

@media screen and (max-width: 1000px) {
    .proc {
        grid-template-columns: 1fr !important;
        position: relative;
    }

    .position-menu {
        display: inline-block !important;
    }

    
    .proc-left {
        box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0.1);
        padding: 0rem 2rem !important;
        position: absolute;
        top: 4.5rem;
        right: 0rem;
        z-index: 3 !important;
        width: 100%;
        background-color: white;
        transform: translateX(-5rem);
        -webkit-transform: translateX(-5rem);
        -moz-transform: translateX(-5rem);
        -ms-transform: translateX(-5rem);
        -o-transform: translateX(-5rem);
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -ms-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        opacity: 0;
}

    .proc-left.active {
        display: block;
        transform: translateX(0rem);
        -webkit-transform: translateX(0rem);
        -moz-transform: translateX(0rem);
        -ms-transform: translateX(0rem);
        -o-transform: translateX(0rem);
        opacity: 1;
}


    .proc-right {

    }
    
}

@media screen and (max-width: 980px) {

    .admin-portal-withsidebar {
        padding: 2rem !important;
    }
    /* * NAVBAR */
    .mainNav {
        /* position: relative; */
    }

    .mainNav .mobile-menu {
        display: -webkit-flex !important;
        display: flex !important;
    }

    .mainNav > div > ul {
        position: fixed;
        background-color: rgba(1, 104, 103, 0.92);
        width: 100%;
        top: 0;
        height: 100vh;
        z-index: 99999;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-around;
                justify-content: space-around;
        overflow-y: scroll;
        transform: translateY(-100vh);
        -webkit-transform: translateY(-100vh);
        -moz-transform: translateY(-100vh);
        -ms-transform: translateY(-100vh);
        -o-transform: translateY(-100vh);
        transition: -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        transition: transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        transition: transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        -webkit-transition: transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        -moz-transition: transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        -ms-transition: transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        -o-transition: transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }

    .mainNav.open-nav > div > ul {
        display: -webkit-flex !important;
        display: flex !important;
        transform: translateY(0vh) !important;
        -webkit-transform: translateY(0vh) !important;
        -moz-transform: translateY(0vh) !important;
        -ms-transform: translateY(0vh) !important;
        -o-transform: translateY(0vh) !important;
    }

    .mainNav ul li {
        /*
        */
        width: 26rem;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }
    .mainNav ul li a {
        border-radius: 0.6rem !important;
        -webkit-border-radius: 0.6rem !important;
        -moz-border-radius: 0.6rem !important;
        -ms-border-radius: 0.6rem !important;
        -o-border-radius: 0.6rem !important;
        font-size: 1.8rem;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
    }
    .mainNav ul li > span:nth-of-type(1) {
        font-size: 1.8rem;
    }

    .nav-dropdown {
        border-radius: 0.6rem;
        -webkit-border-radius: 0.6rem;
        -moz-border-radius: 0.6rem;
        -ms-border-radius: 0.6rem;
        -o-border-radius: 0.6rem;
    }

    .nav-dropdown ul li a {
        text-align: center;
    }
    /* *END: NAVBAR */

    /* * HOME ABOUT */
    .home-about {
        grid-template-columns: 1fr 2fr !important;
    }
    /* * END: HOME ABOUT */

    .admin-portal-sidebar {
        width: 20rem !important;
    }

    .admin-portal-ws-content {
        width: calc(100% - 20rem) !important;
        margin-left: calc(20rem + 1rem) !important;
    }
}

@media screen and (max-width: 948px) {
    .with-sidebar {
        grid-template-columns: 1fr 26rem !important;
    }
}
@media screen and (max-width: 860px) {
    /* * FOOTER */

    .footer-container {
        grid-template-columns: 1fr 1fr !important;
    }

    .company-links-list {
        grid-template-columns: 1fr !important;
    }
    .company-links-list li {
        margin: 0.2rem 0;
    }

    /* * END FOOTER */

    .with-sidebar {
        grid-template-columns: 1fr !important;
    }

    .sidebar {
        margin-top: 3rem;
    }

    .sidebar > div:nth-child(1) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
        gap: 2rem;
    }

    .ws-content {
        margin-right: 0 !important;
    }

    .admin-portal-ws-content {
        padding: 0rem !important;
    }

    .admin-portal-sidebar {
        width: 25rem !important;
        left: -25.5rem;
        z-index: 5;
        position: absolute !important;
    }

    .admin-portal-sidebar.openSide {
        left: 0rem !important;
    }

    .side-nav-overlay.openSide {
        display: block !important;
        position: fixed;
        width: 100%;
        height: 100vh;
        z-index: 4;
        top: 0rem;
        left: 0rem;
        background-color: rgba(0, 0, 0, 0.2);
    }



    .ap-sidebar-btn {
        right: -3.5rem !important;
        display: inline-block !important;
        z-index: 44;
        display: inline-block;
        font-size: 2.2rem;
        /*border-radius: 10rem;
        -webkit-border-radius: 10rem;
        -moz-border-radius: 10rem;
        -ms-border-radius: 10rem;
        -o-border-radius: 10rem;
        */width: 3.5rem;
        height: 3.5rem;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        background-color: var(--primary-color);
        color: white;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -ms-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
    }

    .admin-portal-ws-content {
        padding: 0 1rem;
        margin-left: calc(0rem) !important;
        width: calc(100% - 0rem) !important;
    }
}

@media screen and (max-width: 780px) {
    .login-left {
        margin-right: 0 !important;
        max-width: 100%;
    }

    .login-right {
        display: none !important;
    }
}

@media screen and (max-width: 700px) {
    :root {
        --home-padding: 4rem !important;
    }

    /* * HOME ABOUT */
    .wlc-text {
        font-size: 2rem !important;
    }
    .home-about {
        grid-template-columns: 1fr !important;
        background-color: rgba(255, 255, 255, 0.75);
        margin-top: 2rem;
    }

    #home-about h2 {
        font-size: 3rem !important;
        text-align: center;
    }

    #home-about h2::after {
        margin: 0.2rem auto;
    }

    .ha-right {
        margin-top: 2rem;
    }
    .ha-right p:nth-of-type(3) {
        text-align: center;
    }

    /* * END: HOME ABOUT */

    .sidebar > div:nth-child(1) {
        display: grid;
        grid-template-columns: 1fr !important;
        grid-gap: 2rem;
        gap: 2rem;
    }

    


}
@media screen and (max-width: 660px) {
    :root {
        --home-padding: 3rem !important;
    }

}
@media screen and (max-width: 610px) {
    /* * FOOTER */
    .footer-container {
        grid-template-columns: 1fr !important;
    }
    .footer-container > div:nth-child(2) {
        grid-column: 1/2;
        grid-row: 2/3;
    }

    .company-links-list {
        display: grid;
        grid-template-columns: 1.4fr 1fr !important;
        margin-top: 2rem;
    }

    /* * END FOOTER */
}
@media screen and (max-width: 500px) {
    /* * HEADER */
    .header img {
        min-width: 95rem !important;

    }

    /* *END: HEADER */

}
@media screen and (max-width: 490px) {
    /* * FOOTER */
    .company-links-list {
        display: grid;
        grid-template-columns: 1fr !important;
        margin-top: 2rem;
    }
    .footer-container > div:nth-child(2) {
        grid-column: 1/2;
        grid-row: 3/4;
    }

    /* * END FOOTER */
}
@media screen and (max-width: 470px) {
    /* * HEADER */
    .header img {
        min-width: 90rem !important;

    }

    /* *END: HEADER */

}
@media screen and (max-width: 448px) {
    /* * HEADER */
    .header img {
        min-width: 85rem !important;

    }

    /* *END: HEADER */

}
@media screen and (max-width: 421px) {
    :root {
        --home-padding: 2rem !important;
    }

    /* * HOME SECTION */
    .home-section-two {
        padding: 0 !important;
    }
    .home-section-two > div {
        width: 300px !important;
        padding: 0;
    }
    /* * END HOME SECTION */

    /* * HEADER */
    .header img {
        min-width: 80rem !important;

    }

    /* *END: HEADER */
}
@media screen and (max-width: 400px) {
    /* * HEADER */
    .header img {
        min-width: 75rem !important;

    }

    /* *END: HEADER */

}

@media screen and (max-width: 390px) {
    .login-form {
        width: 30rem !important;
     }
}

@media screen and (max-width: 380px) {

    /* * HOME SECTION */
    .home-section-two > div {
        width: 250px !important;
    }
    /* * END HOME SECTION */

}
@media screen and (max-width: 370px) {
    /* * HEADER */
    .header img {
        min-width: 70rem !important;

    }

    /* *END: HEADER */

}
@media screen and (max-width: 350px) {
    /* * HEADER */
    .header img {
        min-width: 65rem !important;

    }

    /* *END: HEADER */

}

@media screen and (max-width: 345px) {
    .faqs-list li {
        width: 28rem;
    }
}

@media screen and (max-width: 330px) {
    .login-form {
        width: 100% !important;
     }
}


@media screen and (max-width: 325px) {
    /* * HEADER */
    .header img {
        min-width: 60rem !important;


    }

    /* *END: HEADER */

    .faqs-list li {
        width: 25rem;
    }

}
@media screen and (max-width: 300px) {
    /* * HEADER */
    .header img {
        min-width: 55rem !important;

    }

    /* *END: HEADER */

}
/* IMPORT GOOGLE FONT (ARIMO & CABIN) */

/*>>>>LOCAL FONT DECLARATION<<<<<*/
@font-face {
	font-family: Arimo;
	src: url(/static/media/Arimo-Regular.d39eacce.ttf);
}

@font-face {
	font-family: Cabin;
	src: url(/static/media/Cabin-Regular.f54a5208.ttf);
}

:root {
  --primary-color: #016867;
  --secondary-color: #82C017;
  --tertiary-color: #36ACF6;
  --teriary-color-2: #FD5400;
  --font-color-light: #fff;
  --font-color-dark: #000;
  --font-color-lighter: #e1e1ff;
  --font-color-darker: #0d0d0d;
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05); 
  --shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-md:  0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-xl:  0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  --home-padding: 5rem;
  --primary-color-light: #e6fffe;
  --login-right-width: 50%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

body {
  margin: 0;
  font-family: Arimo, Cabin, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  color: black;
  background-color: var(--black-background);
  overflow-x: hidden;
}

h1 {
  font-size: 2.6rem !important;
}

h2 {
  font-size: 2.2rem !important;
}

iframe,
video {
  max-width: 100% !important;
}

iframe {
  width: 100%;
  min-height: 400px;
}

::selection {
  background-color: #36ACF6;
  background-color: var(--tertiary-color);
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.breadcrumb {
  font-weight: 500 !important;
  opacity: 0.85;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.content-max-width {
  max-width: 1380px;
  margin: 0 auto;
}

.formErrorMsg {
	/*color: #843534;*/
	color: #a94442;
	background-color: #f2dede;
	border: 1px solid #ebccd1;
	border-radius: 0.4rem;
	font-size: 1.2rem;
	padding: 0.6rem;
	margin: 1rem 0;
}


.primary-btn {
  margin-right:  1rem;
  padding: 0.4rem 1rem;
  border-radius: 0.5rem;
  display: inline-block;
  background-color: #016867;
  background-color: var(--primary-color);
  color: white;
}

.primary-btn:hover {
  opacity: 0.8;
}

/** >>>>> SWEETALERT STYLES <<<<< */

#swal2-title {
  font-size: 3rem !important;
  padding: 0 !important;
  padding-top: 0.5rem !important;
}

.swal2-popup {
  width: 40rem !important;
  padding-bottom: 2rem !important;
}

.swal2-html-container {
  font-size: 2rem !important;
  padding-top: 1.5rem !important;
}

.swal2-styled {
  font-size: 1.5rem !important;
  padding: 0.8rem 3rem !important;
}

.swal2-styled.swal2-confirm {
  background-color: #016867 !important;
  background-color: var(--primary-color) !important;
}
/** >>>>> END OF SWEETALERT STYLES <<<<< */



/* width */
::-webkit-scrollbar {
  width: 0.8rem;
	height: 0.8rem;
	opacity: 0.7;
}

/* Track */
::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0);
	/* border-radius: 1rem; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #016867;
  background: var(--primary-color);
	/* border-radius: 1rem; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #82C017;
  background: var(--secondary-color);
}


/* --------------  file input and drag n drop styling -------------- */
[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

/* ------------ ----------- -------------- */

input:focus, textarea:focus {
  outline: 1px solid #82C017;
  outline: 1px solid var(--secondary-color);
  outline-offset: 1px;
}

input:disabled, select:disabled {
  cursor: not-allowed;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

button:focus {
  outline: none !important;
  /* outline: 1px solid var(--secondary-color) !important; */
  /* outline-offset: 1px !important; */
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */

/**
 * Remove the border on images inside links in IE 10.
 */

img {
  border-style: none;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select { /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */

textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
   ========================================================================== */

/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */

details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Misc
   ========================================================================== */

/**
 * Add the correct display in IE 10+.
 */

template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */

[hidden] {
  display: none;
}

/**
 * Manually forked from SUIT CSS Base: https://github.com/suitcss/base
 * A thin layer on top of normalize.css that provides a starting point more
 * suitable for web applications.
 */

/**
 * Removes the default spacing and border for appropriate elements.
 */

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

button {
  background-color: transparent;
  background-image: none;
}

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 */

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/**
 * Tailwind custom reset styles
 */

/**
 * 1. Use the user's configured `sans` font-family (with Tailwind's default
 *    sans-serif font stack as a fallback) as a sane default.
 * 2. Use Tailwind's default "normal" line-height so the user isn't forced
 *    to override it to ensure consistency even when using the default theme.
 */

html {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 1 */
  line-height: 1.5; /* 2 */
}

/**
 * 1. Prevent padding and border from affecting element width.
 *
 *    We used to set this in the html element and inherit from
 *    the parent element for everything else. This caused issues
 *    in shadow-dom-enhanced elements like <details> where the content
 *    is wrapped by a div with box-sizing set to `content-box`.
 *
 *    https://github.com/mozdevs/cssremedy/issues/4
 *
 *
 * 2. Allow adding a border to an element by just adding a border-width.
 *
 *    By default, the way the browser specifies that an element should have no
 *    border is by setting it's border-style to `none` in the user-agent
 *    stylesheet.
 *
 *    In order to easily add borders to elements by just setting the `border-width`
 *    property, we change the default border-style for all elements to `solid`, and
 *    use border-width to hide them instead. This way our `border` utilities only
 *    need to set the `border-width` property instead of the entire `border`
 *    shorthand, making our border utilities much more straightforward to compose.
 *
 *    https://github.com/tailwindcss/tailwindcss/pull/116
 */

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: #e2e8f0; /* 2 */
}

/*
 * Ensure horizontal rules are visible by default
 */

hr {
  border-top-width: 1px;
}

/**
 * Undo the `border-style: none` reset that Normalize applies to images so that
 * our `border-{width}` utilities have the expected effect.
 *
 * The Normalize reset is unnecessary for us since we default the border-width
 * to 0 on all elements.
 *
 * https://github.com/tailwindcss/tailwindcss/issues/362
 */

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #a0aec0;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #a0aec0;
}

input::placeholder,
textarea::placeholder {
  color: #a0aec0;
}

button,
[role="button"] {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}


/**
 * Reset links to optimize for opt-in styling instead of
 * opt-out.
 */

a {
  color: inherit;
  text-decoration: inherit;
}

/**
 * Reset form element properties that are easy to forget to
 * style explicitly so you don't inadvertently introduce
 * styles that deviate from your design system. These styles
 * supplement a partial reset that is already applied by
 * normalize.css.
 */

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

/**
 * Use the configured 'mono' font family for elements that
 * are expected to be rendered with a monospace font, falling
 * back to the system monospace stack if there is no configured
 * 'mono' font family.
 */

pre,
code,
kbd,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

/**
 * Make replaced elements `display: block` by default as that's
 * the behavior you want almost all of the time. Inspired by
 * CSS Remedy, with `svg` added as well.
 *
 * https://github.com/mozdevs/cssremedy/issues/14
 */

svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

/**
 * Constrain images and videos to the parent width and preserve
 * their instrinsic aspect ratio.
 *
 * https://github.com/mozdevs/cssremedy/issues/14
 */

img,
video {
  max-width: 100%;
  height: auto;
}
.page-count {
    font-size: 1.3rem;
    font-style: italic;
    margin-top: 1.5rem;
}

.pagination-wrapper {
    margin-top: 1rem;
}

.pagination {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.pagination > span a {
    transition: letter-spacing 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.pagination > span a:hover {
    letter-spacing: 0.1rem;
    color: var(--secondary-color);
}

/* individual page pagination */
.page-pagination {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 1.4rem;
    margin-top: 2rem;
}

.page-pagination .pp-left {
    text-align: left;
}

.page-pagination .pp-right {
    text-align: right;
}

.page-pagination a {
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.page-pagination a:hover {
    color: var(--secondary-color);
}

.header {
    background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDAAYEBAQFBAYFBQYJBgUGCQsIBgYICwwKCgsKCgwQDAwMDAwMEAwODxAPDgwTExQUExMcGxsbHCAgICAgICAgICD/2wBDAQcHBw0MDRgQEBgaFREVGiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICD/wAARCABxAAcDAREAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAcFBv/EAB4QAQAABQUAAAAAAAAAAAAAAAADBAZU0wUXGJKT/8QAGQEBAAMBAQAAAAAAAAAAAAAAAAMFBgQH/8QAHhEBAAAGAwEAAAAAAAAAAAAAAAECAxRR0RUWkaH/2gAMAwEAAhEDEQA/AJqs2FAAAAAAAAAVvjNXl/pfrMYEFxBbcPVzL900XjyxAdntXUNxKd4mNoOt18yex0nt4qo2rsf/2Q==);
    background-position: top;
    text-align: left;
    vertical-align: auto !important;
    overflow-x: hidden;
}

.header img {
    z-index: 1;
    display: inline !important;
    min-width: 100rem;
}
.footer {
    background: var(--primary-color);
    color: var(--font-color-lighter);
}

.footer-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: calc(var(--home-padding) - 2rem);
}

.footer-container > div {
    margin: 2rem;
}

.footer-company-logo-name {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.footer-company-logo-name > div {
    max-width: 6rem;
    margin: 0 2rem 0 0;
}

.footer-company-logo-name h4 {
    display: inline-block;
    font-size: 2rem;
}

.footer-stripe {
    height: 2.6rem;
    background-color: var(--secondary-color);
    border-top: 3px solid white;
    border-bottom: 3px solid white;
}

.footer-last-section {
    background-color: white;
    color: var(--font-color-dark);
    border-top: 10px solid var(--primary-color);
    font-size: 1.5rem;
    padding: 2rem 0;
}

.footer-last-section small {
    padding-left: var(--home-padding);
}

.company-links-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 2rem;
}

.company-links-list li a {
    display: inline-block;
    position: relative;
    padding-left: 2rem;
    transition: 0.3s all ease;
}


.company-links-list li a::before {
    content: "»";
    pointer-events: none;
    display: inline-block;
    margin: 0 1rem 0;
    position: absolute;
    left: -1rem;
}

.company-links-list li a:hover {
    -webkit-transform: translateX(1rem);
            transform: translateX(1rem);
    color: var(--secondary-color);
}

.contact-info-list {
    margin-top: 2rem;
}

.contact-info-list li {
    display: grid;
    grid-template-columns: 7rem 1fr;
    margin: 1rem 0;
}

.contact-info-list li span:nth-child(1) {
    font-weight: 600;
}

.fc-col-2 h3,
.fc-col-3 h3 {
    font-size: 2.2rem;
    position: relative;
}

.fc-col-2 h3:after,
.fc-col-3 h3:after {
    content: '';
    margin: 0.7rem 0;
    width: 6.5rem;
    height: 0.5rem;
    display: block;
    background-color: var(--secondary-color);
}

.footer-last-section > div {
    padding-left: var(--home-padding) !important;
    padding-right: var(--home-padding);
}

.footer-last-section > div small {
    padding: 0;
    display: inline-block;
}

.mainNav {
    text-align: center;
    background-color: var(--primary-color);
    color: white;
    margin-top: -0.25rem;
    z-index: 4000;
}

.mainNav .mobile-menu {
    display: inline-block;
    position: fixed;
    z-index: 999999;
    bottom: 1.2rem;
    left: 1.2rem;
    font-size: 2.5rem;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;
    width: 4rem;
    height: 4rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: var(--secondary-color);
    display: none;
    border: 1px solid white;
}

.mainNav .adm-profile-link span {
    display: none;
    font-size: 1.8rem !important;
}

.mainNav .adm-profile-link {
    display: inline-block;
    /*position: fixed;
    */border: 1px solid red;
    position: fixed;
    color: white;
    bottom: 1.2rem;
    right: 1.2rem;
    height: 4rem;
    font-size: 2.5rem;
    border-radius: 10rem;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 4rem;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    background-color: var(--secondary-color);
    border: 1px solid white;
    z-index: 999999;
}

.mainNav .adm-profile-link:hover {
    width: auto;
    padding: 0 1rem;
}

.mainNav .adm-profile-link:hover span {
    display: inline-block;
}

.mainNav a {
    color: var(--font-color-light)
}

.mainNav ul {
    display: -webkit-flex;
    display: flex;
}

.mainNav ul li {
    list-style: none;
    font-weight: 500;
    cursor: pointer;
}

.mainNav ul li a, .mainNav ul li span {
    display: inline-block;
    height: 100%;
    width: 100%;
    padding: 1rem;
}

.mainNav ul li a:hover {
    background-color: var(--secondary-color);
}

.mainNav ul li span:hover {
    cursor: default;
}

.nav-dropdown-parent {
    position: relative;
}

.nav-dropdown-parent:hover .nav-dropdown {
    opacity:  1;
    top: 4.1rem;
    pointer-events: auto;
}

.nav-dropdown {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 5rem;
    display: block;
    color: var(--font-color-dark);
    transition: all 0.2s ease;
    pointer-events: none;
    background: var(--primary-color);
    color: var(--font-color-light);
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    z-index: 999999;
    overflow: hidden;
}

.nav-dropdown ul {
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    display: block;
    position: relative;
}

.nav-dropdown ul li {
    list-style: none;
    text-align: left;
    white-space: nowrap;
}

.nav-dropdown ul li:hover {
    background-color: var(--secondary-color);
}
.home-layout {
    display: grid;
    padding: 3rem 0 0;
    min-height: 40rem;
    background-image: url(/static/media/asclhomebg.a26c0b45.png);
    background-repeat: no-repeat;
}

.wlc-text {
    text-align: center;
    padding: 0 var(--home-padding);
}

/* .wlc-text:after {
    content: '';
    display: block;
    margin: 0.7rem auto 0;
    width: 6.5rem;
    height: 0.5rem;
    background-color: var(--secondary-color);
} */

.home-about {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: var(--home-padding);
}

.home-about h2 {
    font-size: 3.8rem !important;
    position: relative;
}

.home-about h2:after {
    content: '';
    margin: 0.7rem 0;
    width: 6.5rem;
    height: 0.5rem;
    display: block;
    background-color: var(--secondary-color);
}

.home-about p {
    font-size: 1.8rem;
    margin: 0rem 0rem 2rem;
    color: var(--font-color-darker);
    opacity: 0.85;
    line-height: 2.3rem;
}

.home-read-more {
    font-weight: 500;
    padding: 1.2rem 3rem;
    border-radius: 5rem;
    text-transform: uppercase;
    background: var(--font-color-dark);
    text-decoration: none;
    color: white;
    display: inline-block;
    margin-top: 1rem;
    transition: all 0.3s ease;
}

.home-read-more:hover {
    background-color: var(--primary-color);
}

.home-section-two {
    display: -webkit-flex;
    display: flex;
    margin: 10rem 0 8rem;
    padding: calc(var(--home-padding) - 2rem);
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.home-section-two > div {
    margin: 2rem;
    text-align: center;
    box-shadow: var(--shadow);    
    padding: 3rem;
    width: 340px;
    max-width: 100% !important;
    border-radius: 0.8rem;
}

.home-section-two > div h3 {
    margin: 2rem 0;
    font-size: 2.5rem;
}

.home-section-two > div p {
    opacity: 0.85;
    margin-bottom: 1rem;
}

.home-section-two > div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.hst-icon {
    width: 10rem;
    height: 10rem;
    overflow: hidden;
    border-radius: 10rem;
    margin: 0 auto;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;
}

.hst-icon img {
    max-width: 100%;
    transform: scale(1.4);
    margin-top: 1.2rem;
    pointer-events: none;
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
}

.home-carousel .slide > div {
    height: 100%;
}

.home-carousel .slide > div > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.with-sidebar {
    display: grid;
    grid-template-columns: 1fr 34rem;
    padding: 2rem var(--home-padding);
}

.sidebar  {
    max-width: 100%;
}

.sidebar > div:nth-child(1) {
    max-width: 100%;
}

.ws-content {
    min-height: 40rem;
    margin-right: 3rem;
}

.s-box {
    border-top: 10px solid var(--primary-color);
    box-shadow: var(--shadow-md);
    margin: 0 0 3rem;
}

.s-box-header {
    padding: 0.5rem 1rem 0.5rem;
    background-color: var(--primary-color-light);  
    color: var(--primary-color);
}


.s-box-body {
    padding: 1rem;
}

.s-box-body h5 {
    font-size: medium;
    margin: 0.5rem 0 0.8rem;
}

.s-facilities-list li {
    transition: all 0.3s ease;
    list-style-position:outside;
    white-space: nowrap;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}


.s-facilities-list li a {
    display: inline-block;
    height: 100%;
    padding: 0.8rem 0;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    text-overflow: ellipsis;
    width: calc(100% - 2rem);
    overflow: hidden;
}

.s-facilities-list li a:hover {
    text-decoration: underline;
    color: var(--primary-color)
}

p {
    opacity: 0.9;
    margin-bottom: 1rem;
}

h3 {
    opacity: 0.9;
    margin: 1rem 0 0;
}
.faqs-container {
}

.faqs-list {
    /* width: 73.6rem; */
    margin: 3rem auto 1rem;
    max-width: 100%;
}

.faqs-list li {
    padding: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1.5rem;
    box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.1);
    max-width: 100%;
}

.faqs-list li:not(:last-child) {
    margin-bottom: 2rem;
}

.faqs-list li > div:nth-of-type(1) {
    display: grid;
    grid-template-columns: 1fr auto;
    -webkit-align-content: center;
            align-content: center;
    cursor: pointer;
}

.faqs-list li > div:nth-of-type(1) > span:nth-child(2) {
    /* font-size: 1.8rem; */
}

.faqs-list li > div:nth-of-type(2) {
    overflow-y: hidden;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    padding-top: 1rem;
    opacity: 0.85;
}

.faqs-list li > div:nth-of-type(2).add-height {
    height: auto !important;
}

.gallery-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(-webkit-min-content, 200px));
    grid-template-columns: repeat(auto-fit, minmax(min-content, 200px));
    grid-template-rows: repeat(auto-fit, minmax(-webkit-min-content, 250px));
    grid-template-rows: repeat(auto-fit, minmax(min-content, 250px));
    grid-gap: 4rem;
    grid-gap: 4rem;
    gap: 4rem;
    -webkit-justify-content: center;
            justify-content: center;
}

.gallery-wrapper li {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.gallery-wrapper li img {
    max-height: 100%;
    max-width: 100%;
    display: inline-block;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.gallery-wrapper li img:hover {
    -webkit-transform: scale(1.1) translateY(-1rem);
            transform: scale(1.1) translateY(-1rem);    
}

.gallery-skeleton {
    width: 20rem !important;
    height: 22rem !important;
    position: relative !important;
}
.profiles-container {
}


.profiles-container ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(-webkit-min-content, 200px));
    grid-template-columns: repeat(auto-fit, minmax(min-content, 200px));
    grid-template-rows: repeat(auto-fit, minmax(-webkit-min-content, 250px));
    grid-template-rows: repeat(auto-fit, minmax(min-content, 250px));
    grid-gap: 2rem;
    grid-gap: 2rem;
    gap: 2rem;
    -webkit-justify-content: center;
            justify-content: center;
}

.profiles-container ul li {
    width: auto;
    max-width: 20rem;
    height: 25rem;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
}

.profiles-container ul li > a {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
    height: 100%;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.profiles-container ul li:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.profile-photo-wrapper {
    height: 20rem;
    max-width: 20rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.profile-photo-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

.profiles-container ul li > a > div:nth-of-type(2) {
    /* background-color: rgba(0, 0, 0, 0.1); */
    background-color: #01686710;
}

.profiles-container ul li > a > div:nth-of-type(2) span {
    display: block;
}

.profiles-container ul li > a > div:nth-of-type(2) span:nth-child(2) {
    opacity: 0.8;
}

/* * INDIVIDUAL MANAGEMENT PROFILE PAGE */
.proc {
    /* border: 1px solid red; */
    display: grid;
    grid-template-columns: 22rem 1fr;
    margin-top: 1.5rem;
    grid-gap: 2rem;
    gap: 2rem;
}

.proc-left ul {
    font-size: 1.4rem;

}

.position-menu {
    display: none;
}

.proc-left ul li {
    margin: 1rem 0;
    opacity: 0.85;
}

.proc-left ul li.active-list {
    opacity: 1 !important;
}

.proc-left ul li a {
    display: block;
    padding: 0.5rem 0; 
}

.proc-right h3 {
    margin-bottom: 1rem;
}

.proc-right > div:nth-of-type(1) {
    max-width: 20rem;
    max-height: 24rem;
}

.proc-right > div:nth-of-type(1) img {
    max-width: 100%;
    max-height: 100%;
}

.proc-about {
    margin: 1rem 0;
    opacity: 0.85;
}

.skeleton-loader:empty {
    width: 5rem;
    height: 5rem;
    display: block;
    background: linear-gradient( to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% ), lightgray;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    -webkit-animation: shine2 1s infinite;
    animation: shine2 1s infinite;
    position: absolute;
}
@-webkit-keyframes shine2 {
    to {
        background-position: 100% 0;
    }
}
@keyframes shine2 {
    to {
        background-position: 100% 0;
    }
}

.page-box-left {
    position: relative;
}

.pb-img-wrapper {
    width: 5rem;
    height: 5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.pb-img-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

.page-box {
    display: grid;
    grid-template-columns: 6rem 1fr;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 1rem;
    text-transform: capitalize;
    padding: 0.5rem;
}
.page-box:hover {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
}
.page-box:hover .page-box-right a {
    font-weight: 600 !important;
}

/* *Individual Page */
.page-cover-wrap {
    text-align: center;
    margin: 1rem 0;
}
.page-cover-wrap img {
    max-width: 100%;
    max-height: 40rem;
}

.page-meta {
    font-size: 1.4rem;
}

.exact-page-title {
    margin: 1rem 0 0;
}

.postBody p {
    font-size: 1.6rem;
    margin: 0 0 1rem;
    line-height: 2.8rem;
    word-spacing: 0.1rem;
    opacity: 0.9;
    overflow-wrap: break-word;
}

.postBody pre {
    font-family: Monaco, monospace !important;
}

.postBody h1 {
    font-size: 3rem;
    line-height: 3rem;
    margin: 1rem 0 2rem !important;
    overflow-wrap: break-word;
}

.postBody h2 {
    margin: 3.2rem 0 1.6rem;
    overflow-wrap: break-word;
    font-size: 2.4rem;
    opacity: 0.9 !important;
}

.postBody h3 {
    margin: 2.8rem 0 0.8rem;
    overflow-wrap: break-word;
}

.postBody h4 {
    margin: 0 0 0.8rem;
    overflow-wrap: break-word;
}

.postBody h5,
.postBody h6 {
    overflow-wrap: break-word;
}

.postBody ul {
    margin: 2rem 0;
    line-height: 2.8rem;
    opacity: 0.9;
}
/* code backgroud-color: #f0efed; */
.postBody li {
    line-height: 2.8rem;
    list-style-position: inside;
    margin: 0.4rem 0;
    overflow-wrap: break-word;
    list-style-type: circle;
}
.postBody li > h1:first-child,
.postBody li > h2:first-child,
.postBody li > h3:first-child,
.postBody li > h4:first-child,
.postBody li > h5:first-child,
.postBody li > h6:first-child {
    display: inline-block !important;
}

.postBody li ul li {
    padding: 0 0 0 2.2rem;
}

.postBody code {
    line-height: 2.24rem;
    font-size: 1.35rem;
    font-family: Monaco, monospace !important;
    background: var(--code-elem-bg-color);
    border-radius: 0.4rem;
    padding: 0.4rem;
    font-weight: 500 !important;
}

.postBody a {
    opacity: 1;
    text-decoration: underline;
    font-weight: 500;
}

.postBody blockquote {
    border-left: 4px solid #e5e7eb;
    padding: 0 0 0 1.6rem;
    margin: 1.9rem 0;
    line-height: 2.4em;
    opacity: 0.9;
}

.postBody table {
    font-size: 1.4rem;
    border-collapse: collapse;
    opacity: 0.9;
    overflow-x: scroll;
    margin-bottom: 1rem;
}

.postBody table thead tr {
}

.postBody table tr {
    text-align: left;
    overflow: scroll;
}

.postBody table th {
    padding: 0.1rem 1.2rem 0.1rem 0.1rem;
    text-align: left;

}

.postBody table td {
    padding: 0.8rem 1.2rem 0.8rem 0;
    text-align: left;
    min-width: 20rem;
    width: auto;
    max-width: max-content;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
}

.postBody img {
    display: block;
    max-width: 100%;
    margin: 3.2rem auto;
    line-height: 2.8rem;
}

.login-page {
    height: 100vh;
    overflow: auto;
}

.login-left {
    display: grid;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100vh;
    overflow: auto;
    margin-right: var(--login-right-width);
    padding: 6rem 2rem;

}

.ll-content {
    margin: 0 0 8rem;
}


/* width */
.login-left::-webkit-scrollbar {
    width: 0rem;
    height: 00rem;
    opacity: 0;
}

/* Track */
.login-left::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 1rem;
}

/* Handle */
.login-left::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 1rem;
}

/* Handle on hover */
.login-left::-webkit-scrollbar-thumb:hover {
    background: var(--secondary-color);
}

.login-right {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: fixed;
    height: 100vh;
    width: var(--login-right-width);
    right: 0;
    top: 0;
    pointer-events: none;
}


.lr-content {
    width: 90%;
    height: 90%;
    background-color: var(--primary-color-light);
    border-radius: 2rem;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
}

.ll-content > div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.login-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 4rem;
}

.login-header img {
    width: 6rem;
    height: 6rem;
    display: inline-block;
    margin-right: 1rem;
}

.login-header span {
    font-size: 2.5rem;
}

.login-form {
    width: 35rem;
    max-width: 100% !important;

}

.login-form-header span {
    opacity: 0.7;
}

.login-form-header h1 {
    opacity: 0.9;
    margin: 0 0 0.8rem;
}

.form-group {
    margin: 2.5rem 0;
}

.form-group .form-control,
.form-group label {
    display: block;
}

.form-group label {
    margin-bottom: 0.5rem;
}

.form-control {
    border: 1px solid rgba(0, 0, 0, 0.5);
    width: 100%;
    padding: 1rem;
    border-radius: 0.4rem;
}

.submit-wrapper {
    margin: 3rem 0;
}

.login-form-submit {
    display: block;
    padding: 1rem 0;
    /* background-color: rgba(0, 0, 0, 0.3); */
    /* background-color: black; */
    background-color: #5E8671;
    width: 100%;
    border-radius: 0.4rem;
    color: white;
    transition: all 0.3s ease;
}

.login-form-submit:hover {
    box-shadow: var(--shadow-lg);
    opacity: 0.9;
}

.lr-catchword {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;;
    padding: 1rem;
    text-align: center;
    color: var(--primary-color);
}

.login-form-body {
    padding-bottom: 6rem;
}
.admin-portal-withsidebar {
    display: block;
    /* grid-template-columns:25rem 1fr ; */
    padding: 2rem var(--home-padding);
    position: relative;
    min-height: 60rem;
}

.admin-portal-ws-content {
    /* min-height: 40rem; */
    /* margin-right: var(--home-padding); */
    padding: 0 1rem;
    margin-left: calc(25rem + 1rem);
    width: calc(100% - 25rem);
}

.side-nav-overlay {
    display: none;
}

.admin-portal-sidebar {
    /*min-height: 40rem;
    */width: 25rem;
    position: absolute;
    background: white;
    transition: all 0.4s cubic-bezier(1, 0, 0, 1);
    -webkit-transition: all 0.4s cubic-bezier(1, 0, 0, 1);
    -moz-transition: all 0.4s cubic-bezier(1, 0, 0, 1);
    -ms-transition: all 0.4s cubic-bezier(1, 0, 0, 1);
    -o-transition: all 0.4s cubic-bezier(1, 0, 0, 1);
}

.aps-inner-wrapper {
    position: relative;
}

.ap-sidebar-btn {
    position: absolute;
    right: 0rem;
    top: 0rem;
    display: none;
}

.aps-box {
    border-top: 10px solid var(--primary-color);
    box-shadow: var(--shadow-md);
    margin: 0 0 3rem;
}

.aps-box-header {
    padding: 1rem 1rem 0;
    background-color: var(--primary-color-light);  
    color: var(--primary-color);
    display: -webkit-flex;
    display: flex;
}

.aps-box-body {
    padding: 1rem;
}

.aps-box-body h5 {
    font-size: medium;
    margin: 0.5rem 0 0.8rem;
}

.aps-facilities-list li {
    transition: all 0.3s ease;
    list-style-type: "» ";
    list-style-position: inside;
}

.aps-facilities-list li a {
    display: inline-block;
    height: 100%;
    padding: 0.8rem 0;
    transition: all 0.3s ease;
}

.aps-facilities-list li a:hover {
    text-decoration: underline;
    color: var(--primary-color)
}

.ap-image-box {
    /* border: 1px solid red; */
    background-color: var(--primary-color-light);
    position: relative;
}

.ap-image-box img {
    /* -webkit-clip-path: polygon(0% 0%, 100% 2%, 100% 50%, 69% 100%, 0% 100%);
            clip-path: polygon(0% 0%, 100% 2%, 100% 50%, 69% 100%, 0% 100%); */
    margin-bottom: -1rem;
    

}

.ap-img-watermark {
    text-align: center;
    display: block;
    position: absolute;
    top: 32%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-weight: 700;
    font-size: 3.5rem;
    width: 100%;
    opacity: 0.2;
    color: var(--primary-color);
    text-shadow: 2px 2px 1px black;
    pointer-events: none;
}

.ap-ascl-logo {
    position: absolute;
    display: block;
    bottom: 0.2rem;
    left: 0.2rem;
    pointer-events: none;
}

.ap-ascl-logo img {
    width: 4rem;
}
.file-upload-progress-bar {
	border: 2px solid var(--primary-color);
	height: 3.5rem;
	width: 100%;
	margin-top: 2rem;
    border-radius: 10rem;
    overflow: hidden;
    position: relative;
}

.file-upload-progress-bar div {
	background: var(--secondary-color);
	height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.file-upload-progress-bar span {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-weight: bold;
    pointer-events: none;
}
.ap-main-section {
    padding: 1rem;
    background-color: #F7F7F7;
    border-radius: 0.5rem;
    min-height: 100%;
}

.ap-box {
    background-color: white;
    padding: 0.5rem 1rem;
    box-shadow: var(--shadow);
    border-radius: 0.4rem;
    margin: 0 0 1rem;
}

.ap-main-section-header {
    margin-bottom: 1rem;
}

.ap-main-section-header h2 {
    font-size: 2.2rem;
    opacity: 0.85;
}

.ap-main-section h3 {
    margin: 0;
    font-size: 1.7rem;
    /* margin-bottom: 1rem; */
}

.file-input-container {
    padding: 0 0 0.5rem;
}

.photo-upload-select {
    border: 1px dashed rgba(0, 0, 0, 0.3);
    padding: 1rem;
    width: 20rem;
    height: 20rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
    border-radius: 0.5rem;
    color: rgba(0, 0, 0, 0.75);
}

.document-upload-select,
.video-upload-select {
    border: 1px dashed rgba(0, 0, 0, 0.3);
    padding: 1rem;
    width: 100%;
    height: 10rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
    border-radius: 0.5rem;
    color: rgba(0, 0, 0, 0.75);
}

.document-upload-btn,
.photo-upload-btn,
.video-upload-btn {
    padding: 0.5em 1rem;
    background: var(--primary-color);
    color: white;
    border-radius: 0.5rem;
    font-size: 1.5rem;
    margin-top: 1rem;
    transition: all 0.2s ease;
}
.document-upload-btn:hover,
.photo-upload-btn:hover,
.video-upload-btn:hover {
    opacity: 0.7;
}
.document-upload-btn:focus,
.photo-upload-btn:focus,
.video-upload-btn:focus {
    outline: none;
}

.selected-file-info {
    margin: 2rem 0 1rem;
}

.selected-file-info h5 {
    font-size: 1.5rem;
}

.selected-file-info ul {
    font-size: 1.4rem;
    /* font-style: italic; */
    border-left: 3px solid var(--secondary-color);
    padding-left: 2rem;
    margin: 0.5rem 0;
}

.selected-file-info ul li {
    display: grid;
    grid-template-columns: 18rem 1fr;
    margin: 0.2rem 0;
}
.selected-file-info ul li span:nth-child(2) {
    font-style: italic;
    font-weight: bold;
}

.all-assets-selector {
    border: 2px dashed var(--secondary-color);  
    padding: 1rem 2rem;
    border-radius: 0.5rem;
}

.copy-asset-head {
	margin: 3rem 0 0;
}

.asset-form-select-wrap {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: auto 5rem;
	grid-template-columns: auto 5rem;
	margin: 1rem 0 1rem;
}

.asset-copy-btn {
	font-size: 1.8rem;
	border: none;
	width: 3.9rem;
	height: 3.9rem;
	border-radius: 0.6rem;
	margin-left: 1.2rem;
	opacity: 0.9;
    color: white;
    background-color: var(--secondary-color);
    transition: all 0.2s ease;
}

.asset-copy-btn:hover {
    background: var(--font-color);
    color: var(--secondary-color);
    opacity: 0.7;
}

.css-1pahdxg-control {
    border-color: var(--secondary-color) !important;
    box-shadow: 0 0 0 1px var(--secondary-color) !important;
}

.asset-type-select {
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: var(--secondary-color);
    color: white;
    display: inline-block;
    margin: 1rem 0;
}

.asset-type-select:focus {
    outline: none !important;
}
.add-to-gallery-form input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: block;
    width: 100%;
    padding: 0.4rem 0.8rem;
    border-radius: 0.5rem;
}

.add-to-gallery-form label {
    margin-bottom: 0.5rem;
    display: inline-block;
}

.add-to-gallery-form > div {
    margin: 1rem 0;
}

.full-page-editor > div:nth-child(1) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 1rem 0;
}

.full-page-editor > div:nth-child(1) div:nth-child(1) {
    margin-right: 1rem;
}

.full-page-editor input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: block;
    width: 100%;
    padding: 0.4rem 0.8rem;
    border-radius: 0.5rem;

}

.full-page-editor > div:nth-child(2) {
    margin: 1.5rem 0;
}

.full-page-editor label {
    margin-bottom: 0.5rem;
    display: inline-block;
}

/* * EDITOR STYLING */
.body-editor-wrapper {

}

.body-editor-input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    min-height: 20rem;
    padding: 0 1rem;
}

.rdw-editor-toolbar {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 0.5rem !important;
    padding: 1rem 2rem !important;
}

.rdw-link-modal,
.rdw-embedded-modal {
    height: auto !important;
}

/* * END: EDITOR STYLING */

.full-page-editor-buttons {
    margin: 2rem 0 0;
}

.full-page-editor-btn-1 {
    background-color: var(--primary-color);
    color: white;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
}

.full-page-editor-btn-1:hover {
    box-shadow: var(--shadow-lg);
}
.full-page-editor-btn-2 {
    color: rgba(0, 0, 0, 0.8);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease;
}

.full-page-editor-btn-2:hover {
    background-color: rgba(255, 0, 0, 0.1);
    
}
.page-type-select {
    /* border: 1px solid black; */
    padding: 0.8rem;
    background-color: var(--primary-color-light);
    box-shadow: var(--shadow);
    margin: 1rem 0;
}

.page-type-select:hover {
    box-shadow: var(--shadow-lg);
}

.create-page-container {
    margin: 2rem 0;
}

.edit-page-container {
    margin: 1rem 0;
}

.page-select-wrapper {
    margin: 1rem 0;
}

.page-btn {
    margin-right:  1rem;
    padding: 0.4rem 1rem;
    border-radius: 0.5rem;
    display: inline-block;
    width: 9rem
}

.page-btn:hover {
    opacity: 0.8;
}

.page-edit-btn {
    background-color: var(--primary-color);
    color: white;
}

.page-delete-btn {
    background-color: rgba(255, 30, 30, 0.8);
    color: white;
}

.page-cancel-btn {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
}

.edit-page-action-btns {
    margin: 1.5rem 0;
}
.createprofile-box > div:nth-of-type(1) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1.5rem;
}

.createprofile-box > div:nth-of-type(1) > div:nth-child(1) {
    margin-right: 1rem;
}

.createprofile-box label, .createprofile-box input {
    display: block;
}

.createprofile-box input,
.createprofile-box textarea {
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: block;
    width: 100%;
    padding: 0.4rem 0.8rem;
    border-radius: 0.5rem;
}

.createprofile-box textarea {
    min-height: 20rem;
}

.createprofile-box > div:nth-of-type(2) > div {
    margin-bottom: 1.5rem;
}
