.add-to-gallery-form input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: block;
    width: 100%;
    padding: 0.4rem 0.8rem;
    border-radius: 0.5rem;
}

.add-to-gallery-form label {
    margin-bottom: 0.5rem;
    display: inline-block;
}

.add-to-gallery-form > div {
    margin: 1rem 0;
}
