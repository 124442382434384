.full-page-editor > div:nth-child(1) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 1rem 0;
}

.full-page-editor > div:nth-child(1) div:nth-child(1) {
    margin-right: 1rem;
}

.full-page-editor input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: block;
    width: 100%;
    padding: 0.4rem 0.8rem;
    border-radius: 0.5rem;

}

.full-page-editor > div:nth-child(2) {
    margin: 1.5rem 0;
}

.full-page-editor label {
    margin-bottom: 0.5rem;
    display: inline-block;
}

/* * EDITOR STYLING */
.body-editor-wrapper {

}

.body-editor-input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    min-height: 20rem;
    padding: 0 1rem;
}

.rdw-editor-toolbar {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 0.5rem !important;
    padding: 1rem 2rem !important;
}

.rdw-link-modal,
.rdw-embedded-modal {
    height: auto !important;
}

/* * END: EDITOR STYLING */

.full-page-editor-buttons {
    margin: 2rem 0 0;
}

.full-page-editor-btn-1 {
    background-color: var(--primary-color);
    color: white;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
}

.full-page-editor-btn-1:hover {
    box-shadow: var(--shadow-lg);
}
.full-page-editor-btn-2 {
    color: rgba(0, 0, 0, 0.8);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease;
}

.full-page-editor-btn-2:hover {
    background-color: rgba(255, 0, 0, 0.1);
    
}