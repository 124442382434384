.admin-portal-withsidebar {
    display: block;
    /* grid-template-columns:25rem 1fr ; */
    padding: 2rem var(--home-padding);
    position: relative;
    min-height: 60rem;
}

.admin-portal-ws-content {
    /* min-height: 40rem; */
    /* margin-right: var(--home-padding); */
    padding: 0 1rem;
    margin-left: calc(25rem + 1rem);
    width: calc(100% - 25rem);
}

.side-nav-overlay {
    display: none;
}

.admin-portal-sidebar {
    /*min-height: 40rem;
    */width: 25rem;
    position: absolute;
    background: white;
    transition: all 0.4s cubic-bezier(1, 0, 0, 1);
    -webkit-transition: all 0.4s cubic-bezier(1, 0, 0, 1);
    -moz-transition: all 0.4s cubic-bezier(1, 0, 0, 1);
    -ms-transition: all 0.4s cubic-bezier(1, 0, 0, 1);
    -o-transition: all 0.4s cubic-bezier(1, 0, 0, 1);
}

.aps-inner-wrapper {
    position: relative;
}

.ap-sidebar-btn {
    position: absolute;
    right: 0rem;
    top: 0rem;
    display: none;
}

.aps-box {
    border-top: 10px solid var(--primary-color);
    box-shadow: var(--shadow-md);
    margin: 0 0 3rem;
}

.aps-box-header {
    padding: 1rem 1rem 0;
    background-color: var(--primary-color-light);  
    color: var(--primary-color);
    display: flex;
}

.aps-box-body {
    padding: 1rem;
}

.aps-box-body h5 {
    font-size: medium;
    margin: 0.5rem 0 0.8rem;
}

.aps-facilities-list li {
    transition: all 0.3s ease;
    list-style-type: "» ";
    list-style-position: inside;
}

.aps-facilities-list li a {
    display: inline-block;
    height: 100%;
    padding: 0.8rem 0;
    transition: all 0.3s ease;
}

.aps-facilities-list li a:hover {
    text-decoration: underline;
    color: var(--primary-color)
}

.ap-image-box {
    /* border: 1px solid red; */
    background-color: var(--primary-color-light);
    position: relative;
}

.ap-image-box img {
    /* -webkit-clip-path: polygon(0% 0%, 100% 2%, 100% 50%, 69% 100%, 0% 100%);
            clip-path: polygon(0% 0%, 100% 2%, 100% 50%, 69% 100%, 0% 100%); */
    margin-bottom: -1rem;
    

}

.ap-img-watermark {
    text-align: center;
    display: block;
    position: absolute;
    top: 32%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
    font-size: 3.5rem;
    width: 100%;
    opacity: 0.2;
    color: var(--primary-color);
    text-shadow: 2px 2px 1px black;
    pointer-events: none;
}

.ap-ascl-logo {
    position: absolute;
    display: block;
    bottom: 0.2rem;
    left: 0.2rem;
    pointer-events: none;
}

.ap-ascl-logo img {
    width: 4rem;
}