.home-layout {
    display: grid;
    padding: 3rem 0 0;
    min-height: 40rem;
    background-image: url("../images/asclhomebg.png");
    background-repeat: no-repeat;
}

.wlc-text {
    text-align: center;
    padding: 0 var(--home-padding);
}

/* .wlc-text:after {
    content: '';
    display: block;
    margin: 0.7rem auto 0;
    width: 6.5rem;
    height: 0.5rem;
    background-color: var(--secondary-color);
} */

.home-about {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: var(--home-padding);
}

.home-about h2 {
    font-size: 3.8rem !important;
    position: relative;
}

.home-about h2:after {
    content: '';
    margin: 0.7rem 0;
    width: 6.5rem;
    height: 0.5rem;
    display: block;
    background-color: var(--secondary-color);
}

.home-about p {
    font-size: 1.8rem;
    margin: 0rem 0rem 2rem;
    color: var(--font-color-darker);
    opacity: 0.85;
    line-height: 2.3rem;
}

.home-read-more {
    font-weight: 500;
    padding: 1.2rem 3rem;
    border-radius: 5rem;
    text-transform: uppercase;
    background: var(--font-color-dark);
    text-decoration: none;
    color: white;
    display: inline-block;
    margin-top: 1rem;
    transition: all 0.3s ease;
}

.home-read-more:hover {
    background-color: var(--primary-color);
}

.home-section-two {
    display: flex;
    margin: 10rem 0 8rem;
    padding: calc(var(--home-padding) - 2rem);
    justify-content: center;
    flex-wrap: wrap;
}

.home-section-two > div {
    margin: 2rem;
    text-align: center;
    box-shadow: var(--shadow);    
    padding: 3rem;
    width: 340px;
    max-width: 100% !important;
    border-radius: 0.8rem;
}

.home-section-two > div h3 {
    margin: 2rem 0;
    font-size: 2.5rem;
}

.home-section-two > div p {
    opacity: 0.85;
    margin-bottom: 1rem;
}

.home-section-two > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.hst-icon {
    width: 10rem;
    height: 10rem;
    overflow: hidden;
    border-radius: 10rem;
    margin: 0 auto;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;
}

.hst-icon img {
    max-width: 100%;
    transform: scale(1.4);
    margin-top: 1.2rem;
    pointer-events: none;
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
}

.home-carousel .slide > div {
    height: 100%;
}

.home-carousel .slide > div > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}
