.faqs-container {
}

.faqs-list {
    /* width: 73.6rem; */
    margin: 3rem auto 1rem;
    max-width: 100%;
}

.faqs-list li {
    padding: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1.5rem;
    box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.1);
    max-width: 100%;
}

.faqs-list li:not(:last-child) {
    margin-bottom: 2rem;
}

.faqs-list li > div:nth-of-type(1) {
    display: grid;
    grid-template-columns: 1fr auto;
    align-content: center;
    cursor: pointer;
}

.faqs-list li > div:nth-of-type(1) > span:nth-child(2) {
    /* font-size: 1.8rem; */
}

.faqs-list li > div:nth-of-type(2) {
    overflow-y: hidden;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    padding-top: 1rem;
    opacity: 0.85;
}

.faqs-list li > div:nth-of-type(2).add-height {
    height: auto !important;
}
