.file-upload-progress-bar {
	border: 2px solid var(--primary-color);
	height: 3.5rem;
	width: 100%;
	margin-top: 2rem;
    border-radius: 10rem;
    overflow: hidden;
    position: relative;
}

.file-upload-progress-bar div {
	background: var(--secondary-color);
	height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-upload-progress-bar span {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    pointer-events: none;
}